import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';

import _ from 'lodash';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { CustomFormatterUtil } from 'gw-portals-util-js';
import metadata from './CustomPaymentHistory.metadata.json5';
import styles from './CustomPaymentHistory.module.scss';
import messages from './CustomPaymentHistory.messages';
import { BreakpointTrackerContext } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import { DataTable, DisplayColumn } from '@jutro/datatable';
import CustomPaymentHistoryComponent from '../CustomPaymentHistoryComponent/CustomPaymentHistoryComponent';
import CustomPaymentHistoryModalComponent from '../CustomPaymentHistoryModal/CustomPaymentHistoryModal';

function CustomPaymentHistory(props) {
    const { selectedPaymentHistory } = props;
    const breakpoint = useContext(BreakpointTrackerContext);    
    const translator = useContext(TranslatorContext); 
    const [selectedPaymentHistory_Ext, setSelectedPaymentHistory_Ext] = useState([]);
    const [modalPaymentDetails, setModalPaymentDetails] = useState({ paymentReceipt: '', policyNumber: '', policyTerm: '', paymentMethod: '', paymentDate: '', paymentAmt: '' });
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [selectedPolicyDetails, setSelectedPolicyDetails] = useState();
    selectedPaymentHistory_Ext.map((payHistory, index) => {
        payHistory.id = index;
        payHistory.paymentDate = CustomFormatterUtil.formatDate(payHistory.paymentDate);
        payHistory.formattedAmount = CustomFormatterUtil.formatAmount(payHistory.amount.amount);
    });

    const showBorder = useCallback((rowId) => {
        if ((rowId === (selectedPaymentHistory_Ext.length - 1)) || (rowId % 9 === 0 && rowId > 0)) {
            return false;
        }
        return true;
    }, [selectedPaymentHistory_Ext]);

    function showModal(payAmt, payDate, payMethod, payReceipt) {
        const selectedPolicyTerm = CustomFormatterUtil.formatDateRange(selectedPolicyDetails.effective, selectedPolicyDetails.expiration);
        const requestedPayDetails = {
            paymentReceipt: payReceipt,
            policyNumber: selectedPolicyDetails.policyId,
            policyTerm: selectedPolicyTerm,
            paymentMethod: payMethod,
            paymentDate: payDate,
            paymentAmt: payAmt
        };
        setModalPaymentDetails(requestedPayDetails);
        setShowPaymentModal(true);
    }

    function hideModal() {
        setShowPaymentModal(false);
    }

    const payment_History = useCallback((selected_Payment_History) => {
        return (
            <>

                <DataTable
                    id="withSearching"
                    loadingText="The data is loading..."
                    data={selected_Payment_History}
                    showSearch={false}
                    defaultConfig={{
                        page: 0,
                        pageSize: 10,
                    }}
                >
                    <DisplayColumn
                        header="Payment date"
                        id="name"
                        path="paymentDate"
                        textAlign="left"
                        sortable={false}
                        renderCell={row => (<div className={showBorder(row.id) ? styles.rowStyleBorder : styles.rowStyle}><span> {row.paymentDate}</span></div>)}
                    />
                    <DisplayColumn
                        header="Description"
                        id="description"
                        path="description_Ext"
                        textAlign="left"
                        sortable={false}
                        renderCell={row => (<div className={showBorder(row.id) ? styles.rowStyleBorder : styles.rowStyle}><span> {CustomFormatterUtil.formatPaymentDescription(row.description_Ext)}</span></div>)}
                    />
                    <DisplayColumn
                        header="Paid"
                        id="paid"
                        path="formattedAmount"
                        textAlign="left"
                        className={styles.rowStyle}
                        sortable={false}
                        renderCell={row => (<div className={showBorder(row.id) ? styles.rowStyleBorder : styles.rowStyle}><span className={row.paymentStatus_Ext === 'Rejected' ? (styles.rejected) : styles.paymentHistoryGridValues}> {row.formattedAmount}</span></div>)}

                    />
                    <DisplayColumn
                        header="Payment status"
                        id="paymentstatus"
                        path="paymentStatus_Ext"
                        textAlign="left"
                        sortable={false}
                        renderCell={row => (<div className={showBorder(row.id) ? styles.rowStyleBorder : styles.rowStyle}><span className={row.paymentStatus_Ext === 'Rejected' ? styles.rejected : styles.paymentHistoryGridValues}> {row.paymentStatus_Ext !== undefined ? row.paymentStatus_Ext : "-"}</span></div>)}

                    />
                    <div
                        header='Receipt'
                        id='paymentreceipt'
                        path='paymentMethod_Ext'
                        textAlign='left'
                        sortable={false}
                        renderCell={(row) => (<div className={showBorder(row.id) ? styles.rowStyleBorder : styles.rowStyle}><div onClick={function () { showModal(row.formattedAmount, row.paymentDate, row.paymentMethod_Ext, row.receiptNumber_Ext); }} className={styles.paymentHistoryViewDetails}>  {translator(messages.viewDetails)} </div></div>)}
                    />
                </DataTable>
            </>
        );
    }, [selectedPaymentHistory_Ext]);

    const generateHistoryOverrides = useCallback(() => {
        const overrides = selectedPaymentHistory_Ext.map((payment, index) => {
            return {
                [`PaymentHistory${index}`]: {
                    selectedPaymentHistory: payment,
                    showPaymentReceipt: showModal
                }, [`gridSeparator${index}`]: {
                    visible: index !== (selectedPaymentHistory_Ext.length - 1)
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [selectedPaymentHistory_Ext]);

    useEffect(() => {
        //1634430 - PHSS | "Something went wrong' | P.3 undefined payment History entity
        if (props.selectedPaymentHistory) {
            setSelectedPaymentHistory_Ext(selectedPaymentHistory.paymentHistory_Ext);
        }
    }, [props.selectedPaymentHistory]);

    useEffect(() => {
        if (props.selectedPolicy) {
            setSelectedPolicyDetails(props.selectedPolicy);
        }
    }, [props.selectedPolicy]);

    const overrideProps = {

        paymentHistoryDiv: {
            content: payment_History(selectedPaymentHistory_Ext),
        },
        paymentHistoryIterator: {
            data: selectedPaymentHistory_Ext,
        },
        paymentHistoryModal: {
            modalPaymentDetails: modalPaymentDetails,
            visible: showPaymentModal,
            dismissModal: hideModal
        },

        ...generateHistoryOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            customhistory: CustomPaymentHistoryComponent,
            customhistorymodal: CustomPaymentHistoryModalComponent
        },
    };

    return renderContentFromMetadata(metadata.componentContent, overrideProps, resolvers);
}

CustomPaymentHistory.propTypes = {
};

export default CustomPaymentHistory;
