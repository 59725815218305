import {
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';

import { renderContentFromMetadata } from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import metadata from './CustomPaymentSchedule.metadata.json5';
import styles from './CustomPaymentSchedule.module.scss';
import messages from './CustomPaymentSchedule.messages';
import CustomPaymentScheduleComponent from '../CustomPaymentScheduleComponent/CustomPaymentScheduleComponent';

function CustomPaymentSchedule(props) {
    const { selectedPolicyInvoices, showPaymentSchedule } = props;
    const [selectedPaymentSchedules, setSelectedPaymentSchedules] = useState([]);
    const translator = useContext(TranslatorContext);
    const generateScheduleOverrides = useCallback(() => {
        const overrides = selectedPaymentSchedules.map((payment, index) => {
            return {
                [`PaymentSchedule${index}`]: {
                    selectedPaymentSchedule: payment
                }, [`gridSeparator${index}`]: {
                    visible: index !== (selectedPaymentSchedules.length - 1)
                },
            };
        });
        return Object.assign({}, ...overrides);
    }, [selectedPaymentSchedules]);

    useEffect(() => {
        //1634430 - PHSS | "Something went wrong' | P.3 undefined payment History entity
        if(props.selectedPolicyInvoices) {
            setSelectedPaymentSchedules(selectedPolicyInvoices.invoiceSummary);
        }
    }, [props.selectedPolicyInvoices]);

    const overrideProps = {
        paymentScheduleIterator: {
            data: selectedPaymentSchedules
        },
        dueDateTableTitle: {
            content: translator(messages.dueDate)
        },
        amountBilledTableTitle: {
            content: translator(messages.amountBilled)
        },
        paidTableTitle: {
            content: translator(messages.paid)
        },
        currentDueTableTitle: {
            content: translator(messages.currentDue)
        },
        pastDueTableTitle: {
            content: translator(messages.pastDue)
        },
        expiredOrCancelledPoliciesNotification: {
            visible: !showPaymentSchedule
        },
        paymentScheduleContent: {
            visible: showPaymentSchedule
        },
        ...generateScheduleOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            CustomPaymentScheduleComponent,
        },
    };

    return renderContentFromMetadata(metadata.componentContent, overrideProps, resolvers);
}

CustomPaymentSchedule.propTypes = {
};

export default CustomPaymentSchedule;
